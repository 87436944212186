import Layout from "../layout";
import ProductsBanner from '../pages/products/ProductsBanner';

const Privacy = () => {
  return (
    <Layout>
      <div className="main">
        <ProductsBanner />
        <section className="section section--hero">
            <div className="section__container">
                <h2 className="title title--size-2 title--text-align-center">Privacy Policy</h2>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                      Interior Logic Group Privacy Statement
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">                  
                  Interior Logic Group is committed to protecting your privacy and developing technology that gives you the most powerful and safe online experience. This Statement of Privacy applies to the Interior Logic site (the “Site”) and governs data collection and usage through the Site, which is intended to showcase our products and services for professional homebuilders.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">  
                  Interior Logic Group encourages you to review the privacy statements of Web sites you choose to link to from Interior Logic so that you can understand how those Web sites collect, use and share your information. Interior Logic is not responsible for the privacy statements or other content on Web sites outside of the Interior Logic and Interior Logic family of Web sites.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                      Collection of your Personal Information
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  In general, you do not need to create an account or provide us with any personally identifiable information in order to browse the Site. However, if you apply for a job through the Site, you may choose to provide us with certain information, such as your email address, name, home or work address or telephone number.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  IP address, browser type, domain names, access times and referring website addresses are automatically collected from your device by Interior Logic unless you have blocked this information from being shared.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Use of your Personal Information
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  <b>Authorization to contact you by phone.</b>  You authorize ILG, our affiliates, agents and independent contractors to contact you at any telephone number you provide to us or from which you place a call to us, or any telephone number where we believe we may reach you, using any means of communication, including, but not limited to, calls or text messages to mobile, cellular, wireless or similar devices.  You also represent that you are the owner of any telephone number provided to ILG and have authority to provide such number.  You understand that you may incur charges for receiving such communications and that we are not responsible for any such charges assessed by your phone provider. 
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  <b>Authorization to contact you by automated telephone dialing system and/or artificial voices or prerecorded messages</b>. By providing your telephone number to ILG, you also understand and expressly consent to receive calls or text messages from ILG, our affiliates, agents and independent contractors at the number you provide using an automated telephone dialing system and/or artificial voices or prerecorded messages, including confirmation of your preferences or opt-out as described in section (d) below.  You also represent that you are the owner of any telephone number provided to us and have authority to provide such number.  You understand that you may incur charges for receiving such communications and that we are not responsible for any such charges assessed by your phone provider.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  <b>Authorization to contact you by other means</b>.  You also agree that ILG, our affiliates, agents and independent contractors may use any other medium, as permitted by law and including, but not limited to, mail, e-mail and facsimile, to contact you.  
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  <b>Rights to opt-out or make changes</b>.  You understand that you are not required to agree to sections (a), (b), or (c) above as a condition to obtaining credit from ILG.  If you wish to opt out of section (a), (b), and/or (c), or if you want to change how ILG contacts you, including with respect to any telephone number that we might use, you may call us at 1-800-959-8333 or write to us at privacy@interiorlogicgroup.com
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Disclosure of your Personal Information
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Interior Logic does not sell, rent or lease its customer lists to third parties. Interior Logic may, from time to time, contact you on behalf of external business partners about a particular offering that may be of interest to you. In those cases, your personally identifiable information is not transferred to the third party. In addition, Interior Logic may share personal information with trusted partners to help us perform statistical analysis, send you email or postal mail, provide customer support, or arrange for deliveries. All such third parties are prohibited from using your personal information except to provide these services to Interior Logic, and they are required to maintain the confidentiality of your information. In addition, Interior Logic may provide general demographic, aggregated, or deidentified information about our Site users and customers and their preferences to advertisers and other existing or prospective business partners.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Interior Logic Web sites will further disclose your personal information only if required to do so by law or in the good faith belief that such action is necessary: (a) to conform to the edicts of the law or comply with legal process served on Interior Logic or the site; (b) to protect and defend the rights or property of Interior Logic; (c) to act under exigent circumstances to protect the personal safety of users of Interior Logic, or the public; or (d) as part of a merger, acquisition, or other transaction resulting in a change of control of Interior Logic Group or a sale of substantially all of the assets of the business or of a particular product line or division of the business, in which case personal information may be transferred in connection with the transaction and may become subject to the privacy policy of another entity.However, you will have the opportunity to opt-in to any such transfer if the new entity’s planned processing of your personal information differs materially from that set forth in this privacy statement.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Use of Cookies
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  The Interior Logic Web site use “cookies” to help you personalize your online experience. A cookie is a text file that is placed on your hard disk by a Web page server. Cookies cannot be used to run programs or deliver viruses to your computer. Cookies are uniquely assigned to you, and can only be read by a web server in the domain that issued the cookie to you.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  One of the primary purposes of cookies is to provide a convenience feature to save you time. The purpose of a cookie is to tell the Web server that you have returned to a specific page. For example, if you personalize Interior Logic pages, or register with Interior Logic site or services, a cookie helps Interior Logic to recall your specific information on subsequent visits. This simplifies the process of recording your personal information, such as billing addresses, shipping addresses, and so on. When you return to the same Interior Logic Web site, the information you previously provided can be retrieved, so you can easily use the Interior Logic features that you customized.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  You have the ability to accept or decline cookies. Most Web browsers automatically accept cookies, but you can usually modify your browser setting to decline cookies if you prefer. If you choose to decline cookies, you may not be able to fully experience the interactive features of the Interior Logic services or Web sites you visit.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Security of your Personal Information
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Interior Logic takes reasonable and appropriate measures to secure your personal information from unauthorized access, use or disclosure. Even though we follow reasonable procedures to try to protect the information in our possession, no security system is perfect so we cannot guarantee, and you should not expect, that your information will be secure in all circumstances.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Children
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  We do not provide products and services to children. We do not knowingly collect or solicit information from children under the age of 13. If you are a parent or guardian of a child under the age of thirteen (13) and believe he or she has disclosed personally identifiable information to us, please contact us at communications@interiorlogicgroup.com. A parent or guardian of a child under the age of thirteen (13) may review and request deletion of a child’s personally identifiable information as well as prohibit the use thereof. If we become aware that a child under 13 has provided us with personal information, we will take steps to remove that information.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Your California Privacy Rights
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Effective January 1, 2020, the California Consumer Privacy Act of 2018 (CCPA) affords California consumers (i) the right to request disclosure of data collection and sales practices in connection with the requesting consumer, including the categories of personal information collected, the source of the information, use of the information and, if the information was disclosed or sold to third parties, the categories of personal information disclosed or sold to third parties and the categories of third parties to whom such information was disclosed or sold; (ii) the right to request a copy of the specific personal information collected about the requesting consumer during the 12 months before their request (together with right (i), a “personal information request”); (iii) the right to have such information deleted, subject to certain exceptions; (iv) the right to request that their personal information not be sold to third parties, if applicable; and (v) the right not to be discriminated against because they exercised any of these rights.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  California consumers or their designated authorized agents may submit a personal information or erasure request via email to privacy@interiorlogicgroup.com or by calling 1.800.959.8333.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Interior Logic may need to collect information from the requesting party to verify their identity, or their status as an authorized agent, as the case may be, and may use a two-step process to confirm online requests to delete. It will confirm receipt of your request within 10 days and will respond in full within 45 days (subject to an additional 45-day extension in certain circumstances).
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Interior Logic does not disclose or sell personally identifiable information to third-parties for their “business purposes” or “commercial purposes” as such terms are defined in the CCPA.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Use of the Site
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom privacy-content-paragraph content-paragraph-colo">
                  This Site is hosted in, and our services are provided from, the United States, and this Site is not intended for international users. If you provider your personal information through the Site, you consent to the transfer of your information from your country of residence to the United States.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Changes to this Statement
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom privacy-content-paragraph content-paragraph-colo">
                  Interior Logic Group may occasionally update this Privacy Statement and will note the date of last revision below. Interior Logic encourages you to periodically review this Statement to be informed of how Interior Logic is protecting your information.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  <h3 className="subtitle privacy-subtitle">
                    Contact Information
                  </h3>
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom privacy-content-paragraph content-paragraph-colo">
                  Interior Logic Group welcomes your questions or comments regarding this Privacy Statement. Please contact Interior Logic at privacy@interiorlogicgroup.com.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom">
                  Last Revised: September 20, 2022
                </p>
            </div>
        </section>
      </div>
    </Layout>
  );
};

export default Privacy;
