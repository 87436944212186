/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import { NavLink } from "react-router-dom";
import Layout from "../layout";
import cabinet from "../assets/images/cabinet.png";
import kitchen from "../assets/images/kitchen.png";
import countertop from "../assets/images/countertops.png";
import aboutus from "../assets/images/ilg-aboutus2.png";
import banner from "../assets/images/main-banner-v2.png";
import visualizerVideo from "../assets/videos/visualizer.mp4";
import Video from "../components/Video";
import FloatingBtn from "../components/FloatingBtn";
import { useRef, useState } from "react";
import Loader from "../components/Loader";
import {HashLink} from "react-router-hash-link";
import gallery1 from "../assets/gallery/campaign-01.png";
import gallery5 from "../assets/gallery/campaign-02.png";
import gallery3 from "../assets/gallery/campaign-03.png";
import gallery4 from "../assets/gallery/campaign-04.png";
import flooringCard from "../assets/images/flooring-card.png";

const tooltipText =
  "*Subject to finance approval. Some restrictions may apply. Offers may change from month to month. Ask your kitchen designer for details.";

const Home = () => {
  const [iframeLoader, setIframeLoader] = useState(true);
  const freeQuoteRef = useRef();
  const redirectToPage = (page) => {
    window.location.href = page;
  }

  return (
    <Layout>
      <div className="main" id="form">
        <section
          ref={freeQuoteRef}
          className="section section--hero iframe-section"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className="section__container">
            <div className="banner-txt-wrapper">
              <div className="banner-txt">
                A New Kitchen in {' '}<span className="">10 DAYS</span>? <br/>"No way." "Yes way!"
              </div>        
            </div>
            <div className="iframe-form-top row row--align-right ">
              <div className="form-container">
                <div className="box box--with-iframe">
                  <h1 className="title form-title">
                    Let's Design Your
                    <span className="text-bold-upper"> KITCHEN</span>
                  </h1>
                  <div className="iframe-wrapper-campaign">
                    {iframeLoader && <Loader />}
                    <iframe
                      id="form-iframe-campaign"
                      onLoad={() => {
                        setIframeLoader(false);
                      }}
                      src="https://forms.monday.com/forms/embed/73a85facbbf23e21d86400f537604797?r=use1"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>            
          </div>
        <div className="promo-ads-wrapper-campaign">
            <div className="promo-ad">
            NO INTEREST NO PAYMENTS FOR 12 MONTHS*
            </div>
        </div>
        </section>
        <div className="tooltip-title f-s-3" style={{fontStyle:"italic"}}>{tooltipText}</div>
 

        <section className="section">
          <div className="section__container" style={{ maxWidth:"80%",marginTop: "-4rem" }}>
            <h3 className="title--size-3 title-process custom--text-align-center" style={{ marginTop: "2rem" }}>
                Our Process - get your 10 Day Kitchen 
            </h3>
            <h3 className="title--size-5 custom--text-align-center" style={{ marginTop: "3rem" }}>
                If you know anything about kitchen remodeling, you know a complete kitchen remodel in 10 days - from demo to completion - might sound like fairytale.  
            </h3>
            <h3 className="title--size-4 custom--text-align-center" style={{marginTop: "1rem" }}>
                We assure you, it’s real. We CAN do it in 10 days. 
            </h3>
            <h3 className="title--size-5 custom--text-align-center" style={{marginTop: "1rem" }}>
                We do this all the time. You won’t pay more. Our attention to detail is impeccable. There may be a bit of magic involved.
            </h3>
            <h3 className="title--size-5 title-process custom--text-align-center" style={{ marginTop: "2rem" }}>
              10 DAYS means less disruption for your family and gets you back to your regular routine faster!
            </h3>
            <h3 className="title--size-5 custom--text-align-center" style={{ marginTop: "2rem" }}>
            <div>
              We’re not a Big Box store (ever try to find someone to help you there?)
            </div>
            <div>
              We’re not a local contractor. (Some are great, but there’s always a risk hiring a small company)              
            </div>
            </h3>
          </div>
        </section>
        <section className="section" id="ourProducts" style={{padding: 0}}>
          <div className="section__container" style={{maxWidth: "100%"}}>
            <div className="row">
              <div className="row__column">
                <h2 className="title title--size-2 title--text-align-center">
                  Kitchen Remodel
                </h2>
              </div>
            </div>

            <div className="row" id="cabinets">
              <div className="row__column" id="kitchen" onClick={() => redirectToPage('/kitchen')} style={{cursor : 'pointer'}}>
                <div className="box box--gray img-box flex-column" style={{padding: "40px 28px"}}>
                  <h4 className="title" style={{fontSize:"1.8rem"}}>Full Kitchen Remodel</h4>
                  <p className="paragraph">
                    We know kitchens. We've installed thousands of them. You
                    might say we've installed more kitchens than anyone else.
                  </p>
                  <img src={kitchen} />
                </div>
              </div>

              <div className="row__column" onClick={() => redirectToPage('/cabinets')} style={{cursor : 'pointer'}}>
                <div className="box box--gray img-box flex-column" style={{padding: "40px 28px"}}>
                  <h4 className="title" style={{fontSize:"1.8rem"}}>Cabinets</h4>
                  <p className="paragraph">
                    Let's build something beautiful for you. Upgrade or just
                    update the heart of your home starting with new
                    cabinets.
                  </p>
                  <img src={cabinet} />
                </div>
              </div>

              <div className="row__column" id="countertops" onClick={() => redirectToPage('/countertops')} style={{cursor : 'pointer'}}>
                <div className="box box--gray img-box flex-column" style={{padding: "40px 28px"}}>
                  <h4 className="title" style={{fontSize:"1.8rem"}}>Countertops</h4>
                  <p className="paragraph">
                    Top it all off with a beautiful new countertop. From granite to quartz
                    to solid surface, countertops create the final WOW in your
                    kitchen.
                  </p>
                  <img src={countertop} />
                </div>
              </div>
              <div className="row__column" id="countertops" onClick={() => redirectToPage('/flooring')} style={{cursor : 'pointer'}}>
                <div className="box box--gray img-box flex-column" style={{padding: "40px 28px"}}>
                  <h4 className="title" style={{fontSize:"1.8rem"}}>Flooring</h4>
                  <p className="paragraph">
                    Start your renovation from the ground up with updated flooring.
                    From laminate to vinyl plank, to tile, you'll be floored by the selection and options.
                  </p>
                  <img src={flooringCard} />
                </div>
              </div>
            </div>
          </div>
        </section>

          <section style={{margin: "0 20px 0 20px"}}>
            <div className="row">
              <div className="row__column">
                <h4 className="title title--size-2 title--text-align-center" style={{marginBottom: "0px"}}>
                  Our Gallery
                </h4>
              </div>
            </div>
            <div className="row" style={{ marginTop: "-4rem" }}>
              <div className="home__gallery">
                <a href="/gallery">
                  <img src={gallery1} />
                </a>
                <a href="/gallery">
                  <img src={gallery5} />
                </a>
                <a href="/gallery">
                  <img src={gallery3} />
                </a>
                <a href="/gallery">
                  <img src={gallery4} />
                </a>
              </div>
            </div>
            
              <div className="section__container" style={{ maxWidth: "75%", marginTop: "-6rem" }}>
              <h3 className="title--size-5 custom--text-align-center">
                We’re Interior Logic Group Home Remodeling, trusted by the nation’s top builders to install cabinets, countertops and flooring in thousands of new homes every year. We done it all, from floor to ceiling and learned a few things along the way, namely, how to install beautiful kitchens, on time and to homeowners’ sheer delight.
              </h3>
              </div>
       
        </section>
      </div>
      <FloatingBtn scrollY={600} scrollRef={freeQuoteRef} />
    </Layout>
  );
};

export default Home;
