import "./App.scss";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Home from "./pages/Home";
import Campaign from "./pages/Campaign"
import Cabinets from './pages/products/Cabinates';
import Kitchen from './pages/products/Kitchen';
import Countertops from './pages/products/Countertops';
import Privacy from './pages/Privacy'
import {Gallery} from "./pages/Gallery";
import AboutUs from './pages/AboutUs';
import Financing from './pages/Financing';
import Flooring from "./pages/products/Flooring";
import KitchenRemodel from "./pages/KitchenRemodel";

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/lp1" element={<Campaign/>} />
        <Route path="/gallery" element={<Gallery/>}/>
        <Route path="/home" element={<Home/>} />
        <Route path="/cabinets" element={<Cabinets/>} />
        <Route path="/kitchen" element={<Kitchen/>} />
        <Route path="/countertops" element={<Countertops/>} />
        <Route path="/flooring" element={<Flooring/>} />
        <Route path="/privacy" element={<Privacy/>}/>
        {/* <Route path="/about-us" element={<AboutUs/>}/> */}
        <Route path="/financing" element={<Financing/>}/>
        <Route path="/kitchenremodel" element={<KitchenRemodel/>}/>
      </Routes>
    </Router>
  );
}

export default App;
