import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

const FreeQuoteBtn = (props) => {
  return (

    <div className={props.header ? "header-free-quote-btn" : "free-quote-btn"}>
      <HashLink to="/#form">Schedule TODAY!</HashLink>
    </div>
  );
};

export default FreeQuoteBtn;
