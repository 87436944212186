import Layout from "../../layout";
import ProductsBanner from './ProductsBanner';
import FlooringCover from '../../assets/images/flooring-cover.png';

const Flooring = () => {
    return (
        <Layout>
            <div className="main">
                <ProductsBanner bannerImage={FlooringCover} />
                <section className="section section--hero">
                    <div className="section__container">
                        <h2 className="title title--size-2 title--text-align-center" style={{marginBottom: '25px'}}>Flooring</h2>
                        <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color" style={{marginBottom: '25px'}}>Choosing the right flooring is no small feat.</p>
                        <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color">  Engineered Vinyl Plank, Luxury Vinyl Plank, Tile, and Laminate;
                        </p>
                        <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color" style={{marginBottom: '25px'}}>
                            we offer all the latest materials to lay a beautiful foundation for your new kitchen.</p>
                        <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                            Kitchen flooring has a tough job to do, it has to stand up to little feet, big feet, pets, spills and an occasional wayward art project, all while looking beautiful and stylish. Flooring itself can be a major design element that can lend a traditional, modern or even a rustic feel to your space. Over the past decade or so, the number of available flooring options has absolutely exploded. From colors and textures to plank size and materials, flooring selections are endless.
                        </p>
                        <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                            Tile can look like wood, vinyl flooring can also look and feel like wood. And the new materials are more beautiful, durable, and often more affordable than ever before. It’s truly a designer’s dream but all these options can make choosing the right type of flooring for your home feel a bit overwhelming. Our kitchen designers are true experts and will help guide you through the process of choosing the color, style and material that fits your taste and your budget.
                        </p>
                        <p className="paragraph paragraph--size-medium paragraph--center-container content-paragraph-color">
                            Vinyl flooring can really fool you, and your friends and family too. It looks just like expensive hardwood, it’s easy to maintain and can mirror just about any wood look you can imagine. If you prefer real tile, we can help you choose the right design to make a bold statement, complement your kitchen aesthetic or simply create a relaxed and elegant space. The sky is the limit, and we are here to help choose the flooring to make your kitchen a true masterpiece, from the ground up!
                        </p>
                    </div>
                </section>
            </div>
        </Layout>
    );
};

export default Flooring;
