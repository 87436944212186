import Layout from "../../layout";
import ProductsBanner from './ProductsBanner';
import kitchen from "../../assets/images/kitchen.png";

const Kitchen = () => {
  return (
    <Layout>
      <div className="main">
        <ProductsBanner bannerImage={kitchen}/>
        <section className="section section--hero">
          <div className="section__container">
              <h2 className="title title--size-2 title--text-align-center" style={{marginBottom: '25px'}}>Full Kitchen Remodel</h2>
              <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color" style={{marginBottom: '25px'}}>Today’s kitchen in the hub of the home</p>
              {/* <h4 className="subtitle custom--text-align-center">Full Kitchen Remodel</h4> */}
              <p className="paragraph paragraph--size-medium paragraph--center-container content-paragraph-color">
                  We’ve all heard the saying, the kitchen is the <i>heart of the home</i>, but for today’s families, it’s so much more than a place to grab a quick bite at the countertop or share a full meal with family and friends. The kitchen of today is truly <i>the hub of the home</i>. It has become an office, craft project space, homework area, a place to organize family activities and yes, cook and eat! We don’t live the way we did even 10 years ago, our kitchens are now multi-functional areas, and we spend so much time in them, they need to be not only beautiful but functional and in line with our current lifestyles and needs.
              </p>
              <h4 className="subtitle custom--text-align-center">Beautiful</h4>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Let’s talk beauty. You’re probably visiting this website because you’re thinking about a remodeling project. Whether it’s a full kitchen remodel with new cabinets and countertops - you know - the works, or just a refresh, you have started to do the research and seriously consider your options.
              </p>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Most often, homeowners start this process because of aesthetics, in other words, “I want a new look,” or the more dire, “I really hate this kitchen.” Styles change, and our kitchens get a ton of wear and tear. So, outdated? Worn and not really functional for you or your family? Yes, it’s time. We get it. Let us help you make your kitchen <i>Pinterest board</i> beautiful.
              </p>
              <p className="paragraph paragraph--size-medium paragraph--center-container content-paragraph-color">
                  Our design expertise runs deep. Through our parent company, Interior Logic Group, we have planned, designed and installed thousands of kitchens for the nation’s top builders. At Interior Logic Group Home Remodeling, we don’t just follow the latest kitchen trends – we drive them! Our designers are THE national kitchen experts, and they will work with you to turn those magazine clippings, and design show screen shots into something gorgeous, just for you.
              </p>
              <h4 className="subtitle custom--text-align-center">Functional</h4>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  It’s an office, it’s a craft room, it’s a place to prepare meals – yes, it’s the modern kitchen. From recycling stations to slide-out spice racks to soft-close cabinet doors and drawers, we know what’s new, what’s possible, and we’ll tailor all the extras to fit your family and your lifestyle. Our designers have mastered those little details that make a BIG difference in creating functional and livable spaces.
              </p>
              <p className="paragraph paragraph--size-medium paragraph--center-container content-paragraph-color">
                  Using our exclusive virtual design software, our designer will show you exactly how your new kitchen will look and allow you to perfect the layout and plan almost instantly. We can help bring to life the look, flow and design you’ve probably already designed in your head! And if space planning and design aren’t your strengths, no problem, we are the kitchen experts and will help organize your ideas into a cohesive, and budget-friendly plan. Your Interior Logic Group Home Remodeling designer will be your guide.
              </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Kitchen;
