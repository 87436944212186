import Layout from "../../layout";
import ProductsBanner from './ProductsBanner';
import countertop from '../../assets/images/countertop-cover.png';

const Countertops = () => {
  return (
    <Layout>
      <div className="main">
        <ProductsBanner bannerImage={countertop} />
        <section className="section section--hero">
          <div className="section__container">
              <h2 className="title title--size-2 title--text-align-center" style={{marginBottom: '25px'}}>Countertops</h2>
              <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color" style={{marginBottom: '25px'}}>Top it all off with beautiful new countertops</p>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Marble, Granite, Quartz, Solid Surface; let your inner designer go wild, or not so wild, we do have white too! Countertops are where all the magic happens; they are the icing on the cake; the finishing touch, and we have the goods – all the colors, materials, options, and price points, to top off your kitchen design.
              </p>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  For your convenience, we bring the store to you – you can view the many options in the comfort of your own home – no need to visit showroom after showroom – because that’s a lot of work. And we did the work for you, and countertop samples are heavy, so let us carry them right to your kitchen table.
              </p>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Yes, product selection and a range of options are important, but they have nothing on our installation teams! Our craftsmen are the best in the business and the entire process from beginning to end is, well, seamless! And as a division of Interior Logic Group, our buying power, expertise and lead times deliver the most cost effective, meticulous, and on-time countertop projects possible. Relax, we’ve done this a million times.
              </p>
              <p className="paragraph paragraph--size-medium paragraph--center-container content-paragraph-color">
                  So, let us finish off your kitchen design or just add some new flair to the space. Interior Logic Group Home Remodeling is here to help turn your countertop dreams into countertop reality.
              </p>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Countertops;
