import Layout from "../layout";
import gallery1 from '../assets/gallery/1.jpg'
import gallery2 from '../assets/gallery/2.jpg'
import gallery3 from '../assets/gallery/3.jpg'
import gallery4 from '../assets/gallery/4.jpg'
import gallery5 from '../assets/gallery/5.jpg'
import gallery6 from '../assets/gallery/6.jpg'
import gallery7 from '../assets/gallery/7.jpg'
import gallery8 from '../assets/gallery/8.jpg'
import gallery9 from '../assets/gallery/9.jpg';
import gallery10 from '../assets/gallery/10.jpg';
import gallery11 from '../assets/gallery/11.jpg';
import gallery12 from '../assets/gallery/12.jpg'
import FsLightbox from 'fslightbox-react';
import {useState} from "react";


export const Gallery = () => {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });
    function openLightboxOnSlide(index) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: index + 1
        });
    }

    let images = [
        gallery1,
        gallery2,
        gallery3,
        gallery4,
        gallery5,
        gallery6,
        gallery7,
        gallery8,
        gallery9,
        gallery10,
        gallery11,
        gallery12
    ];
    return (
        <Layout>
            <div className="container" style={{marginTop: '40px'}}>
                <h2 className="title title--size-2 title--text-align-center" style={{marginBottom: "0"}}>
                    Inspiration Gallery
                </h2>
                <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color">Get inspired and and start the process</p>
                <div className="preview-list">
                        <div className="grid">
                            {
                                images.map((k, index) => (
                                    <div className="item" key={index}>
                                        <img src={k} alt={'gallery'} onClick={() => {openLightboxOnSlide(index)}}/>
                                    </div>
                                ))
                            }
                        </div>
                </div>
            </div>
            <FsLightbox
                toggler={ lightboxController.toggler }
                sources={ images }
                slide={lightboxController.slide}
            />

        </Layout>
)
}