const Loader = ({loadingText='Loading...', hideLoadingText}) => {
  return (
    <div className="loader-container">
      <div className="loader">
        <div className="spinner">
          <div className="spinner-icon"></div>
        </div>
        {!hideLoadingText && <>{loadingText}</>}
      </div>
    </div>
  );
};

export default Loader;
