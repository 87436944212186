import {Link, NavLink} from "react-router-dom";
import {HashLink} from "react-router-hash-link";

export const SideNav = ({ showSideNav, toggleSideNav }) => {
  return (
    <div
      id="mySidenav"
      className="sidenav"
      style={{ width: showSideNav ? "300px" : "0" }}
    >
      <a href="#" className="closebtn" onClick={(e) => {e.preventDefault();toggleSideNav()}}>
      <i className="fal fa-close"></i>
      </a>
      <ul className="sidenav_main_list">
        <li>
          <HashLink to={"/#visualizer"} onClick={toggleSideNav}>
            <i className="fal fa-video"></i><p>Visualizer</p>
          </HashLink>
        </li>
        <li>
          <HashLink to="/#ourProducts" onClick={toggleSideNav}>
            <i className="fal fa-cube"></i><p>Our Products</p>
          </HashLink>
          <ul className="products">
            <li>
              <NavLink to="/kitchen" onClick={toggleSideNav}>
                <p>Full Kitchen Remodel</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/cabinets" onClick={toggleSideNav}>
                <p>Cabinets</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/countertops" onClick={toggleSideNav}>
                <p>Counter tops</p>
              </NavLink>
            </li>
            <li>
              <NavLink to="/flooring" onClick={toggleSideNav}>
                <p>Flooring</p>
              </NavLink>
            </li>
          </ul>
        </li>
        <li>
          <HashLink to={"/#ourProcess"} onClick={toggleSideNav}>
            <i className="fal fa-arrows-spin"></i><p>Our Process</p>
          </HashLink>
        </li>
        <li>
          <HashLink to={"/financing"} onClick={toggleSideNav}>
            <i className="fa-regular fa-badge-dollar"></i><p>Financing</p>
          </HashLink>
        </li>
        <li>
          <Link to="/gallery" onClick={toggleSideNav}>
            <i className="fa-solid fa-gallery-thumbnails"></i><p>Gallery</p>
          </Link>
        </li>
        {/* <li>
          <HashLink to="/about-us" onClick={toggleSideNav}>
            <i className="fal fa-file"></i><p>About Us</p>
          </HashLink>
        </li> */}
        <li>
          <HashLink to="/#contactus" onClick={toggleSideNav}>
            <i className="fal fa-message-smile"></i><p>Contact Us</p>
          </HashLink>
        </li>
      </ul>
    </div>
  );
};
