import Layout from "../../layout";
import ProductsBanner from './ProductsBanner';
import cabinet from '../../assets/images/cabinet-cover.png';


const Cabinets = () => {
  return (
    <Layout>
      <div className="main">
        <ProductsBanner bannerImage={cabinet}/>
        <section className="section section--hero">
            <div className="section__container">
                <h2 className="title title--size-2 title--text-align-center" style={{marginBottom: '25px'}}>Cabinets</h2>
                <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color" style={{marginBottom: '25px'}}>Like furniture, with a long-term commitment</p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                    It’s an open and shut case (soft-close of course), kitchen cabinets define the look of the entire space. Wood, painted, modern, traditional; your Interior Logic Group Home Remodeling designer has all the door styles, finishes, materials, and colors to help you create the kitchen of your dreams. Through our years of experience designing thousands of new kitchens, we have researched market to bring you the most beautiful, durable and cost-effective cabinets available, complete with all the latest options and features.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                    Your kitchen is an extremely personal space, and new cabinets make the biggest impact when showcasing your unique personality and design style. When you update the layout, color and options, and with new cabinets, it can change the entire look and feel of your home.
                </p>
                <p className="paragraph paragraph--size-medium paragraph--center-container content-paragraph-color">
                    At Interior Logic Group Home Remodeling, we know kitchens, and we REALLY know cabinets. Our selection of cabinets includes pricing and styles from budget friendly to over the top. Creating your new kitchen delivers a design experience like no other. Let us help you create the best and of course most beautiful plan for your space with new kitchen cabinets.
                </p>
            </div>
        </section>
      </div>
    </Layout>
  );
};

export default Cabinets;
