/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable jsx-a11y/iframe-has-title */
import { NavLink } from "react-router-dom";
import Layout from "../layout";
import cabinet from "../assets/images/cabinet.png";
import kitchen from "../assets/images/kitchen.png";
import countertop from "../assets/images/countertops.png";
import aboutus from "../assets/images/ilg-aboutus2.png";
import banner from "../assets/images/main-banner-v2.png";
import visualizerVideo from "../assets/videos/visualizer.mp4";
import Video from "../components/Video";
import FloatingBtn from "../components/FloatingBtn";
import { useRef, useState } from "react";
import Loader from "../components/Loader";
import {HashLink} from "react-router-hash-link";
import gallery1 from "../assets/gallery/1.jpg";
import gallery5 from "../assets/gallery/5.jpg";
import gallery3 from "../assets/gallery/3.jpg";
import gallery4 from "../assets/gallery/13.jpg";
import flooringCard from "../assets/images/flooring-card.png";

const tooltipText =
  "*Subject to finance approval. Some restrictions may apply. Offers may change from month to month. Ask your kitchen designer for details.";

const Home = () => {
  const [iframeLoader, setIframeLoader] = useState(true);
  const freeQuoteRef = useRef();
  const redirectToPage = (page) => {
    window.location.href = page;
  }
  return (
    <Layout>
      <div className="main" id="form">
        <section
          ref={freeQuoteRef}
          className="section section--hero iframe-section"
          style={{ backgroundImage: `url(${banner})` }}
        >
          <div className="section__container">
            <div className="banner-txt-wrapper">
              <div className="banner-txt">
              Cooking Up Beautiful KITCHENS
              </div>        
            </div>
            <div className="iframe-form-top row row--align-right ">
              <div className="form-container">
                <div className="box box--with-iframe">
                  <h1 className="title form-title">
                    Let's Design Your
                    <span className="text-bold-upper"> KITCHEN</span>
                  </h1>
                  <div className="iframe-wrapper">
                    {iframeLoader && <Loader />}
                    <iframe
                      id="form-iframe"
                      onLoad={() => {
                        setIframeLoader(false);
                      }}
                      src="https://forms.monday.com/forms/embed/73a85facbbf23e21d86400f537604797?r=use1"
                    ></iframe>
                  </div>
                </div>
              </div>
            </div>
            <div className="promo-ads-wrapper">
              <div className="promo-ad">
                FREE TOUCHLESS FAUCET 
                <span className="tooltip">
                  *
                  <span className="tooltip-text hide-mobile-tablet">
                    {tooltipText}
                  </span>
                </span>
              </div>
              <div className="promo-ad">
                Plus, 12 Months NO Interest. NO Payments.**
              </div>
            </div>            
          </div>
        </section>
        <div className="tooltip-title f-s-3" style={{fontStyle:"italic"}}>{tooltipText}</div>
        <section className="section">
          <div className="section__container" style={{ marginTop: "-2rem" }}>
            <h3 className="subtitle custom--text-align-center">
              Interior Logic Group, trusted by the nation’s top builders for kitchen design and installation,<br/> bringing our expertise to your
              kitchen remodel.
            </h3>
          </div>
        </section>
        <section
          className="section section--no-vertical-spacing"
          id="ourProcess"
        >
          <div className="section__container">        
            <h3 className="subtitle title--size-2 custom--text-align-center">
              We Know KITCHENS
            </h3>
            <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color">
              Trusted by the country's top builders. We've installed
              thousands of new kitchens.<br/>You might say we've installed more
              kitchens than anyone else.
            </p>
            <br/><br/><br/>
            <h2 className="title title--size-2 title--text-align-center">
              Our Process
            </h2>
            <div className="row">
              <div className="row__column">
                <div className="box box--with-icon justify-start">
                  <div className="box__icon">1</div>
                  <h4 className="title title--size-6 title-process">
                    Meet Your Kitchen Consultant.
                  </h4>
                  <br />
                  <p className="paragraph content-paragraph-color">
                    Let's get you on our schedule! Choose a convenient date and
                    time and our expert designer will bring the showroom to you.
                  </p>
                </div>
              </div>

              <div className="row__column">
                <div className="box box--with-icon justify-start">
                  <div className="box__icon">2</div>
                  <h4 className="title title--size-6 title-process">
                    You Dream It. We Design It.
                  </h4>
                  <br />
                  <p className="paragraph content-paragraph-color">
                    We'll work with you to create your dream kitchen, and using our
                    interactive design tool, build out a comprehensive rendering, present
                    you with a quote and timeline.
                  </p>
                </div>
              </div>
              <div className="row__column">
                <div className="box box--with-icon justify-start">
                  <div className="box__icon">3</div>
                  <h4 className="title title--size-6 title-process">
                    Trust The Process. We Got This.
                  </h4>
                  <br />
                  <p className="paragraph content-paragraph-color">
                    We measure, order custom materials and coordinate all aspects of your installation. Our expert craftsmen transform your kitchen,
                    leaving you with a beautiful new space.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="section" id="visualizer">
          <div className="section__container">
            <h2 className="title title--size-2 title--text-align-center">
              Visualizer
              <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color">
                Get started with your new kitchen design.
              </p>
            </h2>
            <br/>
            <br/>        
            <div className="row">
              <Video src={visualizerVideo} />
            </div>
          </div>
        </section>
        <section className="section" id="ourProducts">
          <div className="section__container">
            <h2 className="title title--size-2 title--text-align-center">
              Our Products
              <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color">
              Come on in, take a look around.       
              </p>
            </h2>
            <div className="row" id="cabinets">
              <div className="row__column" id="kitchen" onClick={() => redirectToPage('/kitchen')} style={{cursor : 'pointer'}}>
                <div className="box box--gray img-box flex-column">
                  <h4 className="title">Full Kitchen Remodel</h4>
                  <p className="paragraph">
                    We know kitchens. We've installed thousands of them. You
                    might say we've installed more kitchens than anyone else.
                  </p>
                    <img src={kitchen} />
                </div>
              </div>
              <div className="row__column" onClick={() => redirectToPage('/cabinets')} style={{cursor : 'pointer'}}>
                <div className="box box--gray img-box flex-column">
                  <h4 className="title">Cabinets</h4>
                  <p className="paragraph">
                    Let's build something beautiful for you. Upgrade or just
                    update the heart of your home starting with new
                    cabinets.
                  </p>
                  <img src={cabinet} />
                </div>
              </div>

              <div className="row__column" id="countertops" onClick={() => redirectToPage('/countertops')} style={{cursor : 'pointer'}}>
                <div className="box box--gray img-box flex-column">
                  <h4 className="title">Countertops</h4>
                  <p className="paragraph">
                    Top it all off with a beautiful new countertop. From granite to quartz
                    to solid surface, countertops create the final WOW in your
                    kitchen.
                  </p>
                  <img src={countertop} />
                </div>
              </div>
              <div className="row__column" id="countertops" onClick={() => redirectToPage('/flooring')} style={{cursor : 'pointer'}}>
                <div className="box box--gray img-box flex-column">
                  <h4 className="title" style={{fontSize:"1.8rem"}}>Flooring</h4>
                  <p className="paragraph">
                    Start your renovation from the ground up with updated flooring.
                    From laminate to vinyl plank, to tile, you'll be floored by the selection and options.
                  </p>
                  <img src={flooringCard} />
                </div>
              </div>
            </div>          
            <div className="row">
              <div className="row__column">
                <h4 className="title title--size-2 title--text-align-center" style={{marginBottom: "0px"}}>
                  Inspiration Gallery
                </h4>
                <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color">Get inspired and start the process</p>
              </div>
            </div>
            <div className="row">
              <div className="home__gallery">
                <a href="/gallery">
                  <img src={gallery1} />
                </a>
                <a href="/gallery">
                  <img src={gallery5} />
                </a>
                <a href="/gallery">
                  <img src={gallery3} />
                </a>
                <a href="/gallery">
                  <img src={gallery4} />
                </a>
              </div>
            </div>
            <div className="row">
              <div className="row__column">
                <h4 className="title title--size-2 title--text-align-center">
                  Cooking Up Beautiful KITCHENS
                </h4>
                <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color">
                  Schedule your FREE in-home design consultation <HashLink to="#form-iframe">TODAY</HashLink>! 
                </p>
              </div>
            </div>
            <div className="row location-wrapper" id="contactus">
              {/* -- contact info -- */}
              <div className="row__column__contact contact-mobile-width">
                <div className="box box--with-icon">
                  <div className="box__icon">
                    <i className="fa-solid fa-phone-volume"></i>
                  </div>
                  <a href="tel:888 216 5279" className="link">
                    <div className="paragraph phone-number">(888) 216 - 5279</div>
                  </a>
                </div>
              </div>
              <div className="row__column__contact contact-mobile-width">
                <div className="box box--with-icon">
                  <div className="box__icon">
                    <i className="fa-solid fa-envelope"></i>
                  </div>
                  <a href="mailto:homeremodeling@ilginc.com" className="link">
                    <div className="paragraph contact-email">homeremodeling@ilginc.com</div>
                  </a>
                </div>
              </div>
            </div>
            <hr className="mobile-divider" />
            <div className="row location-wrapper">
              <div className="row__column__contact contact-mobile-width">
                <div className="box box--with-icon">
                  <div className="box__icon">
                    <i className="fa-solid fa-location-dot"></i>
                  </div>
                  <h4 className="subtitle">Sacramento</h4>
                  <div className="paragraph">860 Embarcadero Drive, Ste 10</div>
                  <div className="paragraph">West Sacramento, CA 95605</div>
                </div>
              </div>
              <hr className="mobile-divider" />
              <div className="row__column__contact contact-mobile-width">
                <div className="box box--with-icon">
                  <div className="box__icon">
                    <i className="fa-solid fa-location-dot"></i>
                  </div>
                  <h4 className="subtitle">Phoenix</h4>
                  <div className="paragraph">2125 South 7th St, Ste 100</div>
                  <div className="paragraph">Phoenix, AZ 85034</div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section>
          <p className="paragraph--center-container paragraph--text-align-center px-m f-s-3" style={{fontStyle:"italic", padding:"0 10px"}}>
            *With purchase of a new kitchen from Interior Logic Group Home
            Remodeling. Cannot be combined with other offers.
            <br /><br />
            **With approved credit. Interest accrues from purchase date but is
            waived if paid in full in 12 months.
         </p>
        </section>
      </div>
      <FloatingBtn scrollY={600} scrollRef={freeQuoteRef} />
    </Layout>
  );
};

export default Home;
