import { useEffect } from "react";
import banner from "../../assets/images/main-banner.png";
import FreeQuoteBtn from "../../components/FreeQuoteBtn";

const ProductsBanner = (props) => {
  const { bannerImage, height, freeQuoteBtn="true" } = props;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <section
      className="section section--hero"
      style={{
        backgroundImage: `URL(${bannerImage ? bannerImage : banner})`,
        minHeight: "18rem",
        height: height
      }}
    >
      {freeQuoteBtn && <FreeQuoteBtn />}
    </section>
  );
};

export default ProductsBanner;
