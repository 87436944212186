import Footer from "../components/Footer";
import TopNav from "../components/TopNav";
import {SideNav} from "../components/SideNav";
import {useState} from "react";

const Layout = ({children}) => {
    const [showSideNav, setShowSideNav] = useState(false);
    const toggleSideNav = () => {
        setShowSideNav(showSideNav => !showSideNav);
    }
    return(
        <>
            <TopNav toggleSideNav={toggleSideNav}/>
            <SideNav showSideNav={showSideNav} toggleSideNav={toggleSideNav}/>
            {children}
            <Footer />
        </>
    )
}
export default Layout;