import {Link, useLocation} from "react-router-dom";
// import logo from "../assets/images/ilg-kitchen-remodel-logo.png";
import FreeQuoteBtn from "./FreeQuoteBtn";
import logo from '../assets/images/logo-01.png'
import blueLogo from '../media/images/ilg-kitchen-remodel-logo.png';

const TopNav = ({ toggleSideNav }) => {
  const router = useLocation();
  return (
    <div className="navbar-wrapper">
      <nav className="navbar navbar--scrolling" role="navigation">
        <div className="navbar__container">
          <div>   
            <Link to="/">
              <div className="header-text-logo">
                <img className={router.pathname === '/kitchenremodel' ? "kitchen-remodel-logo" : "header-img-logo"} src={router.pathname === '/kitchenremodel' ? blueLogo : logo}/>
              </div>
            </Link>
          </div>
          <div className="navbar__end">
            {/* <FreeQuoteBtn header/> */}
            {router.pathname !== '/kitchenremodel' && <span className="">Schedule a FREE in-home design consultation</span> }
            <a href="tel:888-216-5279" className="link">
              <i className={`fa-regular fa-phone ${router.pathname === '/kitchenremodel' && 'blueColor'}`}></i> <span className={`mobile-number ${router.pathname === '/kitchenremodel' && 'blueColor'}`}>(888) 216-5279</span>
            </a>
            <i className={`fa-regular fa-bars ${router.pathname === '/kitchenremodel' && 'blueColor'}`} onClick={toggleSideNav}></i>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default TopNav;
