import { useEffect, useState } from "react";

const FloatingBtn = ({ scrollY = 600, scrollRef }) => {
  const [showTopBtn, setShowTopBtn] = useState(false);
  useEffect(() => {
    let val = false;
    window.addEventListener("scroll", () => {
      if (window.scrollY > scrollY && !val) {
        val = true;
        setShowTopBtn(val);
      } else if (window.scrollY < scrollY && val) {
        val = false;
        setShowTopBtn(val);
      }
    });
  }, [scrollY]);

  return (
    <>
      {showTopBtn && (
        <div
          className="float-btn"
          onClick={() =>
            scrollRef.current.scrollIntoView({ behavior: "smooth" })
          }
        >
          <i className="fal fa-arrow-up"></i>
        </div>
      )}
    </>
  );
};

export default FloatingBtn;
