import { NavLink } from "react-router-dom";
import logo from "../assets/images/ilg-kitchen-remodel-logo.png";
const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <footer className="footer">
      <div className="footer__container">
        <div className="footer__column">
          <a href="/">
            <img className="footer__brand-logo" src={logo} alt="" />
          </a>
          <p className="privacy-link">
            &copy; {currentYear} Interior Logic Group |{" "}
            <NavLink to="/privacy">Privacy Policy</NavLink>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

/* <div className="footer__column">
  <div>
    <a href="" className="link">
      <span className="link__icon"><i className="fa-solid fa-border-all"></i></span>
      Gallery
    </a>
  </div>
  
  <div>
    <a href="" className="link">
      <span className="link__icon">icon</span>
      Testimonials
    </a>
  </div>

  <div>
    <a href="" className="link">
      <span className="link__icon">icon</span>
      Contact Us
    </a>
  </div>
</div>

<div className="footer__column">
  <div>
    stuff and things
  </div>
</div> */
