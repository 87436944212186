import Layout from '../layout';
import ProductsBanner from './products/ProductsBanner';
import aboutus from "../assets/images/ilg-aboutus2.png"

const Financing = () => {
  return (
    <Layout>
      <div className="main">
        <ProductsBanner bannerImage={aboutus} />
        <section className="section section--hero">
          <div className="section__container">
              <h2 className="title title--size-2 title--text-align-center" style={{marginBottom: '25px'}}>
                Financing
              </h2>
              <p className="paragraph paragraph--size-medium custom--text-align-center content-paragraph-color" style={{marginBottom: '25px'}}>When you’re ready to remodel, we make the process fast and easy!</p>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                Another great reason to remodel your kitchen with Interior Logic Group Home Remodel is the access we provide to robust financing programs at great rates, offered by the best lenders! We make the process fast, easy and design a plan around your budget and your project. Remodeling can be costly, but can you afford to put it off another year? Let's lock in your pricing and your rates and work on this together so you can start that project today!
              </p>

              <h2 className="title title--text-align-center title--size-4-mobile-width-fix">
                Love Your New Kitchen Today<br/> PAY Later or Over Time
              </h2>

              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                  Our options for financing can help make the project you have always dreamed of, affordable and easy, allowing you to make the most of your budget, pay over time or delay payments for one year. We offer several plans and work with lenders on your behalf to make your remodeling project a reality.
              </p>

              <h2 className="title title--size-4 title--text-align-center">
                HOW IT WORKS
              </h2>
              <h2 className="title title--size-4 financing-step-title-wrapper">
              <div className="financing-step-number">
                  <div className="box__icon_no_abs">
                    1
                  </div>
                </div>
                <div className="financing-step-title">
                 
                    Design Consultation
                 
                </div>
              </h2>

              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                At the time of your consultation, your designer will work closely with you to turn your remodeling ideas into a detailed plan.
              </p>


              <h2 className="title title--size-4 financing-step-title-wrapper">
                <div className="financing-step-number">
                  <div className="box__icon_no_abs">
                    2
                  </div>
                </div>
                <div className="financing-step-title">                
                    Financing Options
                  
                </div>
              </h2>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                With all the details of your planned remodel completed, your designer will explain all the options available and help you choose the plan that works best for what you have budgeted and the cost of the project.
              </p>

              <h2 className="title title--size-4 financing-step-title-wrapper">
              <div className="financing-step-number">
                  <div className="box__icon_no_abs">
                    3
                  </div>
                </div>
                <div className="financing-step-title">
         
                    Approvals In Minutes
         
                </div>
              </h2>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                Our lenders will provide you with an approval at the time of your appointment, no waiting or need for follow up appointments.
              </p>
              {/* <p className="paragraph paragraph--size-medium title--text-align-center product-detail-margin-bottom">
                back from our various lenders.
              </p> */}
              <h2 className="title title--size-4 title--text-align-center">
                 We Have Options
              </h2>
                <div className="financing-options-wrapper">
                  <div className="financing-option-item">
                      <div className="check-icon"><i className="fa fa-check"></i></div> <p>DEFERRED PAYMENTS</p>
                  </div>
                  <div className="financing-option-item">
                      <div className="check-icon"><i className="fa fa-check"></i></div> <p>DEFERRED INTEREST</p>
                  </div>
                  <div className="financing-option-item">
                      <div className="check-icon"><i className="fa fa-check"></i></div> <p> LOW MONTHLY PAYMENTS</p>
                  </div>
                </div>
              <br/>
              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom content-paragraph-color">
                Right now, we are offering a special financing plan that features <b>No interest and No payments for 12 months.*</b> Remodel now and pay later, but let’s help make this project affordable for you so you can turn your old kitchen into the one you’ve been dreaming about!
              </p>

              <p className="paragraph paragraph--size-medium paragraph--center-container product-detail-margin-bottom f-s-3" style={{fontStyle:"italic"}}>
                *Subject to finance approval. Some restrictions may apply. Offers may change from month to month. Ask your kitchen designer for details.
              </p>
            
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Financing;
